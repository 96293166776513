export default {
  "space-0": "0rem",
  "space-4": ".25rem",
  "space-6": ".375rem",
  "space-8": ".5rem",
  "space-9": ".562rem",
  "space-12": ".75rem",
  "space-14": ".875rem",
  "space-16": "1rem",
  "space-20": "1.25rem",
  "space-24": "1.5rem",
  "space-28": "1.75rem",
  "space-32": "2rem",
  "space-36": "2.25rem",
  "space-40": "2.5rem",
  "space-48": "3rem",
  "space-58": "3.625rem",
  "space-60": "3.75rem",
  "space-64": "4rem",
  "space-70": "4.375rem",
  "space-72": "4.5rem",
  "space-80": "5rem",
  "space-96": "6rem",
  "space-120": "7.5rem",
  "space-128": "8rem",
  "space-160": "10rem",
};
